import { Themes } from '@/server/repository/hygraph/generated/graphqlTypes';
import {
  AugustScript,
  MomentsDisplay,
  MomentsDisplayBG,
  MomentsText,
  MomentsTextBG,
  MomentsDisplayTR,
  MomentsTextTR,
  Ceramika,
  FuturaPT,
} from '../../styles/fonts';

const Fonts = ({ locale, theme }: { locale: string; theme?: string }) => {
  let fontPrimary = null;
  let fontNeutral = null;
  let fontSecondary = AugustScript;

  if (locale === 'bg_BG') {
    fontPrimary = MomentsDisplayBG;
    fontNeutral = MomentsTextBG;
  }

  if (locale === 'tr_TR') {
    fontPrimary = MomentsDisplayTR;
    fontNeutral = MomentsTextTR;
  }

  if (theme === Themes.suprema) {
    fontPrimary = Ceramika;
    fontNeutral = FuturaPT;
    fontSecondary = FuturaPT;
  }

  if (!fontPrimary) {
    fontPrimary = MomentsDisplay;
  }

  if (!fontNeutral) {
    fontNeutral = MomentsText;
  }

  return (
    <style jsx global>{`
      :root {
        --font-neutral: ${fontNeutral.style.fontFamily};
        --font-primary: ${fontPrimary.style.fontFamily};
        --font-secondary: ${fontSecondary.style.fontFamily};
      }
    `}</style>
  );
};

export default Fonts;
